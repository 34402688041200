<template>
  <v-dialog
    v-model="editGuestsDialogVisible"
    width="500"
  >
    <template #activator="{ on, attrs }">
      <!-- Edit invitees btn -->
      <v-btn
        color="primary"
        class="mb-2 me-1 pa-0"
        dark
        rounded
        v-bind="attrs"
        dir="ltr"
        v-on="on"
      >
        <v-icon
          size="20"
        >
          {{ icons.mdiSquareEditOutline }}
        </v-icon>
        <span class="mx-1">
          {{ $t('Edit') }}
        </span>
      </v-btn>
    </template>
    <!-- Pop up card -->
    <v-card
      flat
      class="rounded-xl text-center"
    >
      <v-card-title
        class="align-center justify-center mb-1"
      >
        <v-icon
          color="primary"
          right
          size="30"
        >
          {{ icons.mdiSquareEditOutline }}
        </v-icon>
        {{ $t('EditingInvitees') }}
        <span class="ms-1">({{ selectedRows.length }})</span>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <!-- Editing invitees form -->
        <v-form
          ref="editingForm"
          class="multi-col-validation pt-4"
        >
          <v-row
            justify="center"
            class="ml-auto"
          >
            <v-col
              cols="12"
              md="6"
              sm="6"
            >
              <!-- group -->
              <v-select
                v-if="$store.state.groupsData.length > 0"
                v-model="userData.groups"
                :items="$store.state.groupsData"
                item-text="name"
                item-value="id"
                :label="$t('ClassGroup')"
                outlined
                small-chips
                rounded
                deletable-chips
                hide-details
                clearable
                class="mb-6"
                dense
                :menu-props="{ 'offset-y': true, transition: 'scale-transition' }"
              >
              </v-select>

              <!-- language -->
              <v-select
                v-model="userData.lang"
                :items="$store.state.eventData.languages"
                :item-text="item => $t(item.lang)"
                item-value="lang"
                :menu-props="{ 'offset-y': true, transition: 'scale-transition' }"
                outlined
                dense
                rounded
                clearable
                :label="$t('Language')"
                hide-details
                class="mb-6"
              >
              </v-select>

              <!-- status -->
              <v-select
                v-model="userData.status"
                :items="statusOptionsStats"
                :label="$t('status')"
                :item-text="item => $t(item.title)"
                item-value="value"
                outlined
                rounded
                dense
                hide-details
                clearable
                class="mb-6"
                :menu-props="{ 'offset-y': true, transition: 'scale-transition' }"
                @change="attendingDialogVisible = userData.status === guestStatusMapping.COMING"
              >
              </v-select>

              <!-- Dialog for field number of attending -->
              <v-dialog
                v-model="attendingDialogVisible"
                width="450"
                scrollable
                persistent
              >
                <v-form ref="attendingForm">
                  <v-card
                    flat
                    class="rounded-xl"
                    max-height="700px"
                  >
                    <v-card-title>
                      {{ $t('WhoAttending') }}
                    </v-card-title>

                    <v-card-subtitle class="px-5">
                      {{ $t('AttendingPerInvitee') }}
                    </v-card-subtitle>

                    <perfect-scrollbar
                      :options="perfectScrollbarOptions"
                      class="scrollBarRecentDecision"
                    >
                      <v-card-text>
                        <v-list
                          class="overflow-y-auto"
                        >
                          <!-- display every invitee on selected rows -->
                          <v-list-item
                            v-for="(item, index) in selectedRows "
                            :key="index"
                            class="d-flex px-0 mt-0"
                          >
                            <v-avatar
                              class="v-avatar-light-bg success--text"
                              size="32"
                            >
                              <span
                                class="font-weight-medium"
                              >{{ avatarText(item.fullName) }}
                              </span>
                            </v-avatar>
                            <div
                              class="d-flex align-center flex-grow-1 flex-wrap"
                              style="padding: 5px"
                            >
                              <div class="me-2 pe-0">
                                <h4 class="font-weight-semibold">
                                  {{ item.fullName }}
                                </h4>
                              </div>
                            </div>
                            <!-- Number of attending invitees -->
                            <div class="d-flex flex-wrap">
                              <v-text-field
                                v-model="userData.countOfGuests[item.id]"
                                :label="$t('NumberGuestAnswer')"
                                :rules="[validators.required]"
                                outlined
                                dense
                                rounded
                                hide-details="auto"
                                type="number"
                                :min="1"
                                style="width: 150px;"
                              >
                              </v-text-field>
                            </div>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </perfect-scrollbar>

                    <v-divider></v-divider>

                    <!-- Actions -->
                    <v-card-actions class="text-center">
                      <v-flex>
                        <v-btn
                          color="primary"
                          rounded
                          class="me-3"
                          @click="saveAttendingCount"
                        >
                          {{ $t('Ok') }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          outlined
                          rounded
                          @click="resetAttendingForm"
                        >
                          {{ $t('Cancel') }}
                        </v-btn>
                      </v-flex>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>

              <!-- Transport options -->
              <v-select
                v-if="($store.state.eventData.transport !== 0)"
                v-model="userData.guestTransports"
                :label="$t('Transport')"
                :items="$store.state.eventData.transports"
                item-text="name"
                item-value="id"
                offset-y
                hide-details
                clearable
                outlined
                rounded
                dense
                class="mb-6"
                :menu-props="{ 'offset-y': true, transition: 'scale-transition' }"
              >
              </v-select>

              <!-- Special meal option -->
              <v-select
                v-if="($store.state.eventData.specialMeal !== mealsTypes.NO_NEED_TO_ASK)"
                v-model="userData.guestSpecialMeals"
                :label="$t('specialMeals')"
                :items="$store.state.eventData.specialMeals"
                :item-text="item => $t(item.name)"
                item-value="value"
                offset-y
                outlined
                rounded
                clearable
                hide-details
                dense
                class="mb-6"
                :menu-props="{ 'offset-y': true, transition: 'scale-transition' }"
              >
              </v-select>

              <!-- if the event not have groups, show btn to create new group -->
              <v-btn
                v-if="$store.state.groupsData.length === 0"
                :to="{name: 'apps-user-manage-group'}"
                color="primary"
                outlined
                rounded
                class="mb-6"
                block
              >
                {{ $t('NewGroup') }}
              </v-btn>

              <!-- Switch for transport -->
              <v-row
                v-if="($store.state.eventData.transport !== 0)"
                class="mt-auto ma-1"
                no-gutters
                justify="center"
              >
                <v-col
                  cols="9"
                  style="display: flex; align-items: flex-start;"
                >
                  <label class="font-weight-bold">{{ $t('AskTransportation') }}</label>
                </v-col>
                <v-col
                  cols="3"
                >
                  <v-switch
                    v-model="userData.canAskTransport"
                    hide-details
                    class="pt-0 mt-0 pb-3"
                  >
                  </v-switch>
                </v-col>
              </v-row>

              <!-- Switch for special meals -->
              <v-row
                v-if="($store.state.eventData.specialMeal !== mealsTypes.NO_NEED_TO_ASK)"
                class="mt-auto ma-1"
                no-gutters
              >
                <v-col
                  cols="9"
                  style="display: flex; align-items: flex-start;"
                >
                  <label class="font-weight-bold">{{ $t('AskSpecialMeals') }}</label>
                </v-col>
                <v-col
                  cols="3"
                >
                  <v-switch
                    v-model="userData.canAskSpecialDish"
                    hide-details
                    class="pt-0 mt-0 pb-3"
                  >
                  </v-switch>
                </v-col>
              </v-row>
            </v-col>

            <!-- Actions -->
            <v-col
              cols="12"
              class="d-flex justify-center"
            >
              <v-flex>
                <v-btn
                  color="primary"
                  rounded
                  class="me-3"
                  @click="saveEditingGuests(selectedRows.map(el=>el))"
                >
                  <!-- type="submit" -->

                  {{ $t('Ok') }}
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  type="reset"
                  rounded
                  @click="cancelEditingGuests"
                >
                  {{ $t('Cancel') }}
                </v-btn>
              </v-flex>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import useGuestStatuses, { guestStatusMapping } from '@/composables/useGuestStatuses'
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import { avatarText } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import { mdiSquareEditOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import useSpecialMealsTypes from '@/composables/useSpecialMealsTypes'

export default {
  components: {
    PerfectScrollbar,
  },
  props: ['selectedRows'],

  setup(props, { emit }) {
    const { mealsTypes } = useSpecialMealsTypes()

    const editGuestsDialogVisible = ref(false)
    const attendingDialogVisible = ref(false)
    const attendingForm = ref()
    const editingForm = ref()
    const { statusOptions } = useGuestStatuses()
    const attendingGuestsCount = ref()

    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))

    // ScrollBar settings
    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // Display only attending and not attending status
    const statusOptionsStats = statusOptions.filter(
      el =>
        // eslint-disable-next-line implicit-arrow-linebreak
        [guestStatusMapping.COMING, guestStatusMapping.NOT_COMING].includes(el.value),
      // eslint-disable-next-line function-paren-newline
    )

    // Forms data
    const userData = ref({
      countOfGuests: {},
    })

    // Attending form
    const saveAttendingCount = () => {
      if (attendingForm.value.validate()) {
        attendingDialogVisible.value = false

        attendingGuestsCount.value = Object.keys(userData.value.countOfGuests).map(key => ({
          guestId: Number(key),
          countOfGuests: Number(userData.value.countOfGuests[key]),
        }))
      }
    }

    // Reset attending pop up form
    const resetAttendingForm = () => {
      attendingForm.value.reset()
      userData.value.status = ''
      attendingDialogVisible.value = false
    }

    // Editing invitees form
    // Reset main pop up form
    const resetEditingForm = () => {
      editingForm.value.reset()
    }

    const saveEditingGuests = item => {
      // eslint-disable-next-line object-curly-newline, operator-linebreak
      const { groups, lang, status, guestTransports, guestSpecialMeals, canAskTransport, canAskSpecialDish } =
        userData.value

      const editData = {
        guestIds: item.map(el => el.id),
        ...(groups ? { groups: [groups] } : {}),
        lang,
        status,
        ...(guestTransports ? { guestTransports: [guestTransports] } : {}),
        ...(guestSpecialMeals ? { guestSpecialMeals: [guestSpecialMeals] } : {}),
        canAskTransport,
        canAskSpecialDish,
        attendingGuestsCount: attendingGuestsCount.value,
      }
      store
        .dispatch('inviteList/editManyGuests', { guestsData: editData })
        .then(() => {
          editGuestsDialogVisible.value = false
          emit('selectedRows', [])
          store.dispatch('inviteList/fetchGuests', { payload: { shouldLoad: true } })
        })
        .catch(() => showToast())
        .finally(() => {
          resetEditingForm()
        })
    }

    const cancelEditingGuests = () => {
      resetEditingForm()
      editGuestsDialogVisible.value = false
      emit('selectedRows', [])
    }

    return {
      editGuestsDialogVisible,
      userData,
      statusOptionsStats,
      attendingDialogVisible,
      perfectScrollbarOptions,
      avatarText,
      attendingForm,
      saveAttendingCount,
      saveEditingGuests,
      cancelEditingGuests,
      editingForm,
      resetAttendingForm,
      attendingGuestsCount,
      guestStatusMapping,
      mealsTypes,

      icons: {
        mdiSquareEditOutline,
      },
      validators: {
        required,
      },
    }
  },
}
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';
.scrollBarRecentDecision {
  max-height: calc(var(--vh, 1vh) * 80);
}
</style>
