<template>
  <!-- side card -->
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card
      height="100%"
      flat
    >
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">{{ $t('AddNewInvitee') }}</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>
      <!-- New guest Details -->
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          col="12"
          md="12"
          sm="12"
        >
          <v-card-text>
            <v-form
              ref="form"
              v-model="inviteeForm"
              @submit.prevent="onSubmit"
            >
              <!-- Full name -->
              <v-text-field
                v-model="inviteeData.fullName"
                outlined
                dense
                rounded
                :rules="[validators.required, validators.maxLenInput, validators.minLenInput]"
                :label="$t('fullName')"
                :placeholder="$t('fullName')"
                hide-details="auto"
                maxlength="31"
                class="mb-6"
              ></v-text-field>

              <!-- phone number -->
              <v-text-field
                v-model="inviteeData.phoneNumber"
                :rules="[validators.required, validators.integerValidator, validators.maxPhoneNumber, validators.invalidPhoneNumber, validators.PhoneNumberLength ]"
                outlined
                dense
                rounded
                :label="$t('phoneNumber')"
                placeholder="0522222222"
                maxlength="11"
                hide-details="auto"
                class="mb-6"
                type="number"
                hide-spin-buttons
              >
              </v-text-field>

              <!-- number of invitees -->
              <v-text-field
                v-model.number="inviteeData.numberOfInvitees"
                :rules="[validators.requiredNumber, validators.validateInviteeNumber]"
                outlined
                dense
                rounded
                type="number"
                :label="$t('countOfInvites')"
                :placeholder="$t('countOfInvites')"
                hide-details="auto"
                class="mb-6"
                :min="1"
              ></v-text-field>

              <!-- language -->
              <v-select
                v-model="inviteeData.langOption"
                :items="$store.state.eventData.languages"
                :rules="[validators.required]"
                :item-text="item => $t(item.lang)"
                item-value="lang"
                :label="$t('Language')"
                :menu-props="['offset-y']"
                hide-details="auto"
                class="mb-6"
                outlined
                rounded
                dense
              ></v-select>

              <!-- transport switch -->
              <v-row
                v-if="($store.state.eventData.transport !== 0)"
                class="mt-auto ma-1"
                no-gutters
              >
                <v-col
                  cols="10"
                  style="display: flex; align-items: flex-start;"
                >
                  <label class="font-weight-bold">{{ $t('AskTransportation') }}</label>
                </v-col>
                <v-col
                  cols="2"
                >
                  <v-switch
                    v-model="inviteeData.transSwitch"
                    hide-details
                    class="pt-0 mt-0 pb-5"
                  >
                  </v-switch>
                </v-col>
              </v-row>

              <!-- special meals switch -->
              <v-row
                v-if="($store.state.eventData.specialMeal !== mealsTypes.NO_NEED_TO_ASK)"
                class="mt-auto ma-1"
                no-gutters
              >
                <v-col
                  cols="10"
                  style="display: flex; align-items: flex-start;"
                >
                  <label class="font-weight-bold">{{ $t('AskSpecialMeals') }}</label>
                </v-col>
                <v-col
                  cols="2"
                >
                  <v-switch
                    v-model="inviteeData.mealsSwitch"
                    hide-details
                    class="pt-0 mt-0 pb-3"
                  >
                  </v-switch>
                </v-col>
              </v-row>

              <!-- group classification -->
              <v-row class="mt-1 mr-0">
                <v-col
                  class="mt-0 pr-0"
                >
                  <v-dialog
                    v-model="isDialogVisible"
                    width="400px"
                  >
                    <template
                      #activator="{ on, attrs }"
                    >
                      <v-chip
                        color="primary"
                        text-color="white"
                        v-bind="attrs"
                        class="justify-center"
                        v-on="on"
                      >
                        <v-icon
                          left
                          size="20"
                          style="transform:rotate(180deg)"
                        >
                          {{ icons.mdiLabel }}
                        </v-icon>
                        {{ $t('addToGroup') }}
                      </v-chip>
                    </template>

                    <v-card
                      class="d-flex flex-column rounded-xl"
                      height="300px"
                      flat
                      style="overflow: hidden"
                    >
                      <v-card-title>
                        {{ $t('addToGroup') }}
                      </v-card-title>

                      <v-card-text class="pt-3">
                        <v-select
                          v-if="$store.state.groupsData.length > 0"
                          v-model="inviteeData.saveGroups"
                          :items="$store.state.groupsData"
                          item-text="name"
                          item-value="id"
                          :label="$t('ClassGroup')"
                          clearable
                          :menu-props="{ maxHeight: '210' }"
                        >
                        </v-select>

                        <!-- if event not have groups, show message and btn to group page -->
                        <v-card
                          v-if="$store.state.groupsData.length === 0"
                          class="justify-center"
                          justify="center"
                          color="transparent"
                          style="box-shadow: 0 0px 0px 0"
                          flat
                        >
                          <v-card-title class="mt-0 pb-1 text-subtitle-1 justify-center">
                            {{ $t('messageForNumberOfGroups') }} {{ $store.state.groupsData.length }} {{ $t('Groups') }}
                          </v-card-title>

                          <v-card-actions class="justify-center">
                            <v-btn
                              class="mt-2"
                              :to="{name: 'apps-user-manage-group'}"
                              color="primary"
                              text
                              rounded
                            >
                              {{ $t('NewGroup') }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-card-text>

                      <v-card-actions
                        class="mt-auto justify-center"
                      >
                        <v-btn
                          color="primary"
                          rounded
                          @click="isDialogVisible = false"
                        >
                          {{ $t('Close') }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <!-- Show selected groups on form -->
                  <template
                    v-if="(inviteeData.saveGroups)"
                    class="pr-0"
                  >
                    <v-chip
                      class="ma-1 mx-3"
                      outlined
                      color="primary"
                    >
                      {{ $store.state.groupsData.filter((el) => el.id === inviteeData.saveGroups)[0].name }}
                    </v-chip>
                  </template>
                </v-col>
              </v-row>

              <v-row>
                <div
                  class="mx-auto mt-15"
                >
                  <v-btn
                    color="primary"
                    type="submit"
                    class="me-2 px-2"
                    rounded
                  >
                    {{ $t('Add') }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    outlined
                    type="reset"
                    class="px-2"
                    rounded
                    @click="resetUserData"
                  >
                    {{ $t('Cancel') }}
                  </v-btn>
                </div>
              </v-row>
            </v-form>
            <!-- Limit adding guests when the package is fully used -->
            <v-dialog
              v-model="showLimitReachedPopup"
              persistent
              width="500"
            >
              <v-card
                height="100%"
                flat
                class="rounded-xl"
              >
                <v-card-title
                  class="headline align-center justify-center pb-2"
                  style="word-break: break-word"
                >
                  <v-icon
                    right
                    color="primary"
                    size="40"
                  >
                    {{ icons.mdiAlertCircleOutline }}
                  </v-icon>
                </v-card-title>
                <v-card-text
                  class="text-center pt-0 mt-0"
                  style="word-break: break-word"
                >
                  <div class="text-xl font-weight-bold">
                    {{ $t('FullyUsedPackage') }}
                  </div>
                  <span>
                    {{ $t('CantAddInvitee') }}
                  </span>
                </v-card-text>

                <v-card-actions
                  class="mt-6"
                >
                  <v-spacer></v-spacer>

                  <v-flex>
                    <v-btn
                      color="primary"
                      class="me-2"
                      rounded
                      :to="{name: 'pricing-page', params: { id: $store.state.eventData.id }}"
                      @click="showLimitReachedPopup = false"
                    >
                      {{ $t('UpgradePackage') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      outlined
                      rounded
                      @click="showLimitReachedPopup = false"
                    >
                      {{ $t('Cancel') }}
                    </v-btn>
                  </v-flex>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import useSpecialMealsTypes from '@/composables/useSpecialMealsTypes'
import { i18n } from '@/plugins/i18n/index'
import store from '@/store'
import {
  PhoneNumberLength,
  integerValidator,
  invalidPhoneNumber,
  maxLenInput,
  maxPhoneNumber,
  minLenInput,
  required,
  requiredNumber,
  validateInviteeNumber,
} from '@core/utils/validation'
import { mdiAlertCircleOutline, mdiLabel } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import Vue from 'vue'
import { useToast } from 'vue-toastification/composition'

export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { mealsTypes } = useSpecialMealsTypes()
    const inviteeForm = ref(false)
    const form = ref(null)
    const isDialogVisible = ref(false)
    const toast = useToast()
    const showToast = error => toast.error(i18n.t(error))
    const successToast = (val, fullName) => toast.success(`${fullName} ${i18n.t(val)}`)

    const showLimitReachedPopup = computed({
      get: () => store.getters['inviteList/getShowLimitReachedPopup'],
      set: value => store.commit('inviteList/setShowLimitReachedPopup', value),
    })

    const inviteeData = ref({
      fullName: '',
      phoneNumber: '',
      transSwitch: store.state.eventData.transport === 2 ?? false,
      mealsSwitch: false,
      saveGroups: null,
      countOfInvites: '',
      langOption: store.state.eventData.defaultLang,
    })

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const resetUserData = () => {
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    // Post request to add a new guest.
    const onSubmit = () => {
      if (inviteeForm.value) {
        // eslint-disable-next-line object-curly-newline, operator-linebreak
        const { fullName, phoneNumber, numberOfInvitees, saveGroups, langOption, transSwitch, mealsSwitch } =
          inviteeData.value

        const data = {
          fullName,
          phoneNumber: `+972${phoneNumber.slice(1, 10)}`,
          countOfInvites: numberOfInvitees,
          groups: [saveGroups],
          lang: langOption,
          canAskTransport: transSwitch,
          canAskSpecialDish: mealsSwitch,
        }

        store
          .dispatch('createNewGuest', { id: store.state.eventData.id, guestData: data })
          .then(res => {
            // Track the successful addition of a new guest
            Vue.prototype.$gtag.event('Add_New_Guest', {
              event_category: 'Guest Management',
              event_label: 'Manual',
            })
            emit('refetch-data')
            emit('update:is-add-new-user-sidebar-active', false)
            successToast('SuccessfullyAddedOneGuest', res.data.fullName)
          })
          .catch(error => {
            if (error.response?.data?.message === 'reached_limit') {
              showLimitReachedPopup.value = true
            } else if (error.response?.data?.message === 'guest_phone_number_exists') {
              showToast('PhoneNumExist')
            } else {
              showToast('errorManageEvents')
            }
          })
        resetUserData()
      } else {
        validate()
      }
    }

    return {
      resetUserData,
      form,
      onSubmit,
      inviteeForm,
      validate,
      inviteeData,
      isDialogVisible,
      showLimitReachedPopup,
      mealsTypes,

      validators: {
        required,
        integerValidator,
        maxLenInput,
        minLenInput,
        maxPhoneNumber,
        requiredNumber,
        invalidPhoneNumber,
        validateInviteeNumber,
        PhoneNumberLength,
      },
      icons: {
        mdiLabel,
        mdiAlertCircleOutline,
      },
    }
  },
}
</script>
