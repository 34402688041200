<template>
  <div>
    <CreateNewGuest
      v-model="isAddNewUserSidebarActive"
      @refetch-data="fetchUserTable"
    ></CreateNewGuest>

    <v-card
      class="mt-5 rounded-xl"
      flat
    >
      <v-card-title>
        <v-row
          align="center"
          justify="center"
          class="mx-0 px-0 pb-0"
        >
          <!-- search on watch-->
          <v-col
            cols="12"
            md="4"
            sm="7"
            class="d-flex mx-0 px-0"
          >
            <v-text-field
              v-model="searchQuery"
              :placeholder="$t('search')"
              outlined
              hide-details
              dense
              class="mb-2"
              rounded
            >
            </v-text-field>
          </v-col>

          <!-- all buttons in title -->
          <v-col
            cols="12"
            md="8"
            xs="6"
            class="d-flex justify-end"
          >
            <v-row :justify="$vuetify.breakpoint.smAndDown ? 'center' : 'end'">
              <!-- Create new guest btn -->
              <v-btn
                color="primary"
                rounded
                :disabled="$store.state.eventData.progressStatus === 2"
                class="mb-2 me-1 pa-2"
                dir="ltr"
                @click.stop="isAddNewUserSidebarActive = !isAddNewUserSidebarActive"
              >
                <v-icon
                  size="20"
                >
                  {{ icons.mdiAccountPlus }}
                </v-icon>
                <span class="mx-1">{{ $t('AddNewInvitee') }}</span>
              </v-btn>
              <!-- Import guests from Google/Excel button -->
              <v-menu
                v-model="menuOpen"
                offset-y
                style="z-index: 999;"
                min-width="100"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    class="mb-2 me-1 pr-3 pl-1"
                    v-bind="attrs"
                    rounded
                    :disabled="$store.state.eventData.progressStatus === 2"
                    dir="ltr"
                    v-on="on"
                  >
                    <v-icon size="20">
                      {{ icons.mdiMenuDown }}
                    </v-icon>
                    <span class="mx-0">{{ $t('ImportGuests') }}</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      class="mb-2 px-0 mx-0"
                    >
                      <ImportFromExcel></ImportFromExcel>
                    </v-list-item>
                    <v-list-item
                      class="px-0 mx-0"
                    >
                      <!-- import contacts to the guests table -->
                      <google-contacts @closeMenu="menuOpen = false"></google-contacts>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>

              <!-- edit guests btn -->
              <div>
                <QuickEditGuests
                  v-if="selectedRows.length > 0"
                  :selected-rows="selectedRows"
                  @selectedRows="el => selectedRows = el"
                ></QuickEditGuests>
              </div>

              <!-- Dialog for delete selected rows -->
              <div v-if="selectedRows.length > 0">
                <v-dialog
                  v-model="deleteGuestsDialogVisible"
                  width="500"
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="mb-2 me-1 pa-0"
                      dark
                      rounded
                      v-bind="attrs"
                      dir="ltr"
                      v-on="on"
                    >
                      <v-icon
                        size="20"
                      >
                        {{ icons.mdiDeleteEmptyOutline }}
                      </v-icon>
                      <span class="mx-1">
                        {{ $t('delete') }}
                      </span>
                    </v-btn>
                  </template>
                  <v-card
                    height="100%"
                    flat
                    class="rounded-xl text-center"
                  >
                    <v-card-title
                      class="align-center justify-center mb-1"
                    >
                      <v-icon
                        color="primary"
                        right
                        size="40"
                      >
                        {{ icons.mdiAlertCircleOutline }}
                      </v-icon>
                    </v-card-title>
                    <v-card-subtitle
                      class="text-xl pb-0"
                      style="word-break: break-word"
                    >
                      {{ $t('messageBeforeDelete') }}
                    </v-card-subtitle>
                    <v-card-text
                      class="text-center mt-3"
                      style="word-break: break-word"
                    >
                      {{ selectedRows.map(el=>el.fullName).join(', ') }}
                    </v-card-text>

                    <v-card-actions
                      class="mt-6"
                    >
                      <v-flex>
                        <v-btn
                          color="primary"
                          rounded
                          class="me-3"
                          @click="deleteGuests(selectedRows.map(el=>el.id)); deleteGuestsDialogVisible = false"
                        >
                          {{ $t('Ok') }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          outlined
                          rounded
                          @click="deleteGuestsDialogVisible = false, selectedRows = []"
                        >
                          {{ $t('Cancel') }}
                        </v-btn>
                      </v-flex>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="unDeletedDialogVisible"
                  width="500"
                  persistent
                >
                  <v-card
                    height="100%"
                    flat
                    class="rounded-xl text-center"
                  >
                    <v-card-title
                      class="align-center justify-center mb-1"
                    >
                      <v-icon
                        color="primary"
                        right
                        size="40"
                      >
                        {{ icons.mdiAlertCircleOutline }}
                      </v-icon>
                    </v-card-title>
                    <v-card-subtitle
                      class="text-xl pb-0"
                      style="word-break: break-word"
                    >
                      <div>
                        {{ $t('oops') }}
                      </div>
                      <span>
                        {{ $t('cantDeleteGuestsInProcess') }}
                      </span>
                    </v-card-subtitle>

                    <v-card-text class="d-flex justify-center align-center mt-3">
                      <div
                        v-for="(guest,index) in unDeleted"
                        :key="index"
                      >
                        <span>
                          {{ guest }}
                        </span>
                        <span
                          v-if="index < unDeleted.length - 1"
                        >
                          ,&nbsp;
                        </span>
                      </div>
                    </v-card-text>
                    <v-card-actions
                      class="mt-6 justify-center align-center"
                    >
                      <v-btn
                        color="primary"
                        outlined
                        rounded
                        @click="unDeletedDialogVisible = false; $store.dispatch('inviteList/fetchGuests', { payload: { shouldLoad: false } });"
                      >
                        {{ $t('Ok') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>

              <!-- Export data table to excel -->
              <v-menu
                v-model="templateExport"
                offset-y
                style="z-index: 999;"
                min-width="100"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    class="mb-2 me-1 pa-2 black--text"
                    rounded
                    dir="ltr"
                    v-on="on"
                  >
                    <v-icon
                      size="20"
                    >
                      {{ icons.mdiFileMoveOutline }}
                    </v-icon>
                    <span class="mx-1">{{ $t('Export') }}</span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      @click="exportToRegularExcel"
                    >
                      <v-btn
                        text
                        color="primary"
                        class="pa-0"
                      >
                        <span>
                          {{ $t('RegularExcelTemplate') }}
                        </span>
                      </v-btn>
                    </v-list-item>
                    <v-list-item @click="exportToIplanExcel">
                      <v-btn
                        text
                        class="pa-0"
                      >
                        <span>
                          {{ $t('IplanExcelTemplate') }}
                        </span>
                      </v-btn>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <!-- Guests table -->
      <div>
        <v-data-table
          v-model="selectedRows"
          :headers="tableColumns"
          :items="$store.getters['inviteList/getUserListTable']"
          :no-data-text="$t('NoHaveInvitees')"
          :header-props="{ sortByText: $t('SortBy') }"
          show-select
          hide-default-footer
          :items-per-page="itemsPerPage"
          :page.sync="page"
          @page-count="pageCount = $event"
          @click:row="openEditGuest"
        >
          <template
            #[`item.tableIndex`]="{index, item}"
          >
            <!-- Display index -->
            <div class="d-flex">
              <div class="">
                {{ index + 1 + (page - 1) * itemsPerPage }}
              </div>
              <div class="mx-2">
                <v-icon
                  v-if="item.guestViews.length > 0"
                  color="blue"
                >
                  {{ icons.mdiEye }}
                </v-icon>
              </div>
            </div>
          </template>
          <!-- name -->
          <template #[`header.fullName`]>
            <span class="header-center"> {{ $t('fullName') }} </span>
          </template>

          <template #[`item.fullName`]="{item}">
            <div
              class="d-flex align-center"
            >
              <v-avatar
                class="v-avatar-light-bg"
                :color="getStatusOptionStatsField(item.status, 'color')"
                :class="`${getStatusOptionStatsField(item.status, 'color')}--text`"
                size="32"
              >
                <span
                  class="font-weight-medium"
                >{{ avatarText(item.fullName) }}</span>
              </v-avatar>

              <div class="d-flex flex-column ms-2">
                <span
                  class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
                >
                  {{ item.fullName }}
                </span>
              </div>
            </div>
          </template>

          <!-- phone number -->
          <template #[`header.phoneNumber`]>
            <span class="header-center"> {{ $t('phoneNumber') }} </span>
          </template>

          <template #[`item.phoneNumber`]="{item}">
            <div class="text-capitalize text--primary">
              {{ item.phoneNumber.slice(0,4) === '+972' ? 0+item.phoneNumber.slice(4,15) : item.phoneNumber }}
            </div>
          </template>

          <!-- group -->
          <template #[`header.classGroup`]>
            <span class="header-center"> {{ $t('ClassGroup') }} </span>
          </template>

          <template #[`item.classGroup`]="{item}">
            <div
              v-for="group in item.groups"
              :key="group.id"
              class="d-flex align-center justify-center"
            >
              <v-chip
                class="ma-1"
                small
                outlined
                color="primary"
              >
                <span class="text-capitalize">{{ group.name }}</span>
              </v-chip>
            </div>
          </template>

          <!-- number guests that answer -->
          <template #[`header.NumberGuestAnswer`]>
            <span class="header-center"> {{ $t('NumberGuestAnswer') }} </span>
          </template>

          <template #[`item.NumberGuestAnswer`]="{item}">
            <div class="d-flex align-center justify-center text-center">
              <span
                v-if="item.status === guestStatusMapping.COMING || item.status === guestStatusMapping.NOT_COMING"
                class="font-weight-medium"
              >
                {{ item.countOfGuests.toString() }} {{ $t('outOf') }} {{ item.countOfInvites }}
              </span>
            </div>
          </template>

          <!-- transport places from guests answer -->
          <template #[`header.trans`]>
            <span class="header-center"> {{ $t('Transport') }} </span>
          </template>

          <template #[`item.trans`]="{item}">
            <span class="justify-center">
              {{ item.guestTransports ? item.guestTransports.map(el=> el.name)[0]: '' }}
            </span>
          </template>

          <!-- special meal from guests answer -->
          <template #[`header.meal`]>
            <span class="header-center"> {{ $t('specialMeals') }} </span>
          </template>

          <template #[`item.meal`]="{item}">
            <span class="justify-center">
              {{ item.guestSpecialMeals ? item.guestSpecialMeals.filter(el => el.value !== 0).map(el=> $t(el.name))[0] : '' }}

            </span>
          </template>

          <!-- comments from guests answer -->
          <template #[`header.comment`]>
            <span class="header-center"> {{ $t('comments') }} </span>
          </template>

          <template #[`item.comment`]="{item}">
            <span class="justify-center">
              {{ item.comment.replace('wrong_number', $t('wrongNumber')) }}
            </span>
          </template>

          <!-- status -->
          <template #[`header.status`]>
            <span class="header-center">
              {{ $t('status') }}
            </span>
          </template>

          <template #[`item.status`]="{item}">
            <v-chip
              small
              :color="getStatusOptionStatsField(item.status, 'color')"
              :class="`${getStatusOptionStatsField(item.status, 'color')}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ $t(getStatusOptionStatsField(item.status, 'title')) }}
            </v-chip>
          </template>

          <!-- progress -->
          <template #[`header.progress`]>
            <span class="header-center"> {{ $t('Progress') }} </span>
          </template>

          <template #[`item.progress`]="{item}">
            <v-row class="flex-nowrap justify-center">
              <v-badge
                overlap
                :color="item.guestActions.smsCount > 0 ? 'primary white--text': 'grey black--text'"
                class="me-3"
                left
                offset-x="10"
                offset-y="10"
                :content="`${item.guestActions.smsCount}`"
              >
                <v-avatar
                  size="32"
                  color="grey"
                  class="primary--text"
                >
                  <v-icon
                    size="18"
                    :class="item.guestActions.smsCount > 0 ? 'primary--text' : 'black--text'"
                  >
                    {{ icons.mdiMessageProcessingOutline }}
                  </v-icon>
                </v-avatar>
              </v-badge>

              <v-badge
                :color="item.guestActions.callCount > 0 ? 'primary white--text': 'grey black--text'"
                left
                offset-x="10"
                offset-y="10"
                :content="`${item.guestActions.callCount}`"
              >
                <v-avatar
                  color="grey"
                  size="30"
                >
                  <v-icon
                    size="18"
                    :class="item.guestActions.callCount > 0 ? 'primary--text' : 'black--text'"
                  >
                    {{ icons.mdiPhoneOutline }}
                  </v-icon>
                </v-avatar>
              </v-badge>
            </v-row>
          </template>
        </v-data-table>
        <div class="text-center pt-2 pb-5">
          <v-pagination
            v-model="page"
            :length="pageCount"
            class="guest-table-pagination"
          ></v-pagination>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import useGuestStatuses, { guestStatusMapping } from '@/composables/useGuestStatuses'
import { i18n } from '@/plugins/i18n/index'
import router from '@/router'
import store from '@/store'
import CreateNewGuest from '@/views/EventViews/GuestManagement/CreateNewGuest.vue'
import useUsersList from '@/views/apps/user/user-list/useUsersList'
import { avatarText } from '@core/utils/filter'
import {
  mdiAccountPlus,
  mdiAlertCircleOutline,
  mdiDeleteEmptyOutline,
  mdiEye,
  mdiFileMoveOutline,
  mdiMenuDown,
  mdiMessageProcessingOutline,
  mdiPhoneOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import GoogleContacts from './ImportInvitees/GoogleContacts.vue'
import ImportFromExcel from './ImportInvitees/ImportFromExcel.vue'
import QuickEditGuests from './QuickEditGuests.vue'

export default {
  components: {
    CreateNewGuest,
    GoogleContacts,
    QuickEditGuests,
    ImportFromExcel,
  },

  setup() {
    const searchQuery = ref('')
    const isAddNewUserSidebarActive = ref(false)
    const deleteGuestsDialogVisible = ref(false)
    const unDeletedDialogVisible = ref(false)
    const selectedRows = ref([])
    const menuOpen = ref(false)
    const templateExport = ref(false)

    const editGuestsDialogVisible = ref(false)

    const toast = useToast()
    const showToast = () => toast.error(i18n.t('errorManageEvents'))

    // pagination
    const itemsPerPage = ref(20)
    const page = ref(1)
    const pageCount = ref(0)

    const { statusOptions } = useGuestStatuses()

    // eslint-disable-next-line object-curly-newline
    const { tableColumns, fetchUserTable, displayTrans, displayMeals } = useUsersList()

    displayTrans()
    displayMeals()

    const openEditGuest = guest => {
      router.push({ name: 'apps-user-edit', params: { guestID: guest.id, guestData: guest } })
    }
    const unDeleted = ref()

    const deleteGuests = guestIds => {
      store
        .dispatch('inviteList/deleteGuests', { guestIds })
        .then(res => {
          if (res.data.length > 0) {
            unDeleted.value = res.data
            unDeletedDialogVisible.value = true
          } else {
            store.dispatch('inviteList/fetchGuests', { payload: { shouldLoad: true } })
          }
        })
        .catch(() => showToast())
    }

    const exportToRegularExcel = () => {
      store
        .dispatch('inviteList/exportDataToRegularExcel', { lang: i18n.locale })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })

          const downloadLink = document.createElement('a')
          downloadLink.href = window.URL.createObjectURL(blob)
          downloadLink.download = `${
            store.state.eventData.names[i18n.locale] || store.state.eventData.names[store.state.eventData.defaultLang]
          }.xlsx`
          downloadLink.click()

          window.URL.revokeObjectURL(downloadLink.href)
        })
        .catch(() => showToast())
    }

    const exportToIplanExcel = () => {
      store
        .dispatch('inviteList/exportDataToIplanExcel') // Assuming this returns .xls data
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.ms-excel', // MIME type for .xls files
          })

          const downloadLink = document.createElement('a')
          downloadLink.href = window.URL.createObjectURL(blob)
          downloadLink.download = `${
            store.state.eventData.names[i18n.locale] || store.state.eventData.names[store.state.eventData.defaultLang]
          }.xls` // Ensure the extension is .xls
          downloadLink.click()

          window.URL.revokeObjectURL(downloadLink.href)
        })
        .catch(() => showToast()) // showToast() handles error notification
    }

    const getStatusOptionStatsField = (key, field) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      statusOptions.filter(el => el.value === key).map(el => el[field])[0]

    watch([searchQuery], () => {
      const queryLowered = searchQuery.value
      store.dispatch('inviteList/internalSearchGuests', { queryLowered })
    })

    return {
      searchQuery,
      isAddNewUserSidebarActive,
      fetchUserTable,
      deleteGuestsDialogVisible,
      selectedRows,
      exportToRegularExcel,
      tableColumns,
      openEditGuest,
      statusOptions,
      deleteGuests,
      avatarText,
      page,
      pageCount,
      itemsPerPage,
      editGuestsDialogVisible,
      guestStatusMapping,
      getStatusOptionStatsField,
      unDeleted,
      unDeletedDialogVisible,
      menuOpen,
      templateExport,
      exportToIplanExcel,

      icons: {
        mdiAlertCircleOutline,
        mdiFileMoveOutline,
        mdiMessageProcessingOutline,
        mdiPhoneOutline,
        mdiAccountPlus,
        mdiDeleteEmptyOutline,
        mdiMenuDown,
        mdiEye,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
.v-data-table-header th {
  white-space: nowrap;
}
.disable-events {
  pointer-events: none;
}

.v-data-table__wrapper tr {
  cursor: pointer;
}
.guest-table-pagination .v-pagination__item {
  border-radius: 50%;
}
.header-center {
  display: inline-table;
  width: 100%;
}
</style>
