<template>
  <v-card
    flat
    class="rounded-xl"
    height="100%"
  >
    <v-card-title class="pb-8 ont-weight-semibold">
      {{ $t('EventDetails') }}
    </v-card-title>
    <v-card-text>
      <v-row
        justify="center"
        align="center"
      >
        <v-col
          v-for="(data,key) in guestStatsData"
          :key="key"
          cols="auto"
          class="d-flex flex-grow-1 cursor-pointer"
        >
          <v-avatar
            :size="$vuetify.breakpoint.mdAndDown? 40 : 50"
            :color="getStatusOptionStatsField(key, 'color')"
            :class="`v-avatar-light-bg ${getStatusOptionStatsField(key, 'color')}--text`"
          >
            <v-icon
              size="25"
              :color="getStatusOptionStatsField(key, 'color')"
              class="rounded-0"
            >
              {{ getStatusOptionStatsField(key, 'icon') }}
            </v-icon>
          </v-avatar>

          <div
            class="mx-1"
          >
            <p class="text-s mb-0">
              {{ $t(key) }}
            </p>
            <h2 class="text-xl font-weight-semibold mb-0">
              {{ data }}
            </h2>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import useGuestStatuses from '@/composables/useGuestStatuses'
import store from '@/store'
import { ref } from '@vue/composition-api'

export default {
  setup() {
    const guestStatsData = ref()
    const { statusOptions } = useGuestStatuses()
    const fetchStatisticsEvent = () => {
      store.dispatch('eventStatisticsDetails', { id: store.state.eventData.id }).then(res => {
        guestStatsData.value = res.data.guestStats

        // filtering out 'pending' or 'inProgress'
        const filterKey = store.state.eventData.progressStatus > 0 ? 'pending' : 'inProgress'
        const { [filterKey]: _, changedDecision, ...filteredStats } = guestStatsData.value
        guestStatsData.value = filteredStats
      })
    }

    fetchStatisticsEvent()

    const getStatusOptionStatsField = (key, field) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      statusOptions.filter(el => el.title === key).map(el => el[field])[0]

    return {
      guestStatsData,
      fetchStatisticsEvent,
      statusOptions,
      getStatusOptionStatsField,
    }
  },
}
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
